<template>
  <NuxtLayout name="default">
    <div class="error-page">
      <h1 class="h1 text-center">Ошибка №{{$error?.statusCode || 404}}</h1>
      <h2 class="h2 text-center">
        <template v-if="$error?.statusCode === 404">
          Страница не найдена
        </template>
        <template v-else>
          Произошла внутренняя ошибка. Попробуйте повторить позже.
        </template>
      </h2>
      <client-only>
        <lottie src="/lottie/404.lottie"/>
      </client-only>
      <pre style="display:none;">
      {{ $error }}
      </pre>
      <a href="/" class="btn fill-violet" style="width: 250px;max-width: 100%;margin: auto;margin-top: 50px">На главную</a>
    </div>
  </NuxtLayout>
</template>

<script setup lang="ts">
import getMeta from "~/classes/getMeta";

definePageMeta({
  layout: "default",
  name: "error",
});

const $error = useError();

useHead(getMeta(
  'Ошибка №'+($error?.statusCode || 404),
  'Попробуйте повторить позже'
))
</script>

<style lang="scss">
</style>
