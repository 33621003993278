import revive_payload_client_4sVQNw7RlN from "D:/WebProjects/xsquare-reports-2024/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "D:/WebProjects/xsquare-reports-2024/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "D:/WebProjects/xsquare-reports-2024/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "D:/WebProjects/xsquare-reports-2024/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "D:/WebProjects/xsquare-reports-2024/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "D:/WebProjects/xsquare-reports-2024/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "D:/WebProjects/xsquare-reports-2024/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_iGLjBbor9q from "D:/WebProjects/xsquare-reports-2024/node_modules/nuxt-jsonld/dist/runtime/plugin.js";
import chunk_reload_client_UciE0i6zes from "D:/WebProjects/xsquare-reports-2024/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _01_copyright_year_aaJFr6UcM0 from "D:/WebProjects/xsquare-reports-2024/plugins/01.copyright-year.ts";
import _02_api_bgyRz33mcA from "D:/WebProjects/xsquare-reports-2024/plugins/02.api.ts";
import _03_mobile_detect_client_js_rM1mPg9Xg8 from "D:/WebProjects/xsquare-reports-2024/plugins/03.mobile-detect.client.js.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_iGLjBbor9q,
  chunk_reload_client_UciE0i6zes,
  _01_copyright_year_aaJFr6UcM0,
  _02_api_bgyRz33mcA,
  _03_mobile_detect_client_js_rM1mPg9Xg8
]