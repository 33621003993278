import {defineNuxtPlugin} from "#app/nuxt";
import projectConfig from "~/configs/project-config";

export default defineNuxtPlugin((NuxtApp) => {
    return {
        provide: {
            getCopyrightYear: (year: number) => {
                if(projectConfig.projectYear < year) {
                    return `${projectConfig.projectYear}-${year}`
                } else {
                    return projectConfig.projectYear;
                }
            }
        }
    }
})
