export default function (title : string, description : string, image: string = "/share.jpg") {
    return {
        title: title,
        meta: [
            { name: 'title', content: title },
            { name: 'description', content: description },

            { itemprop: 'main', content: title },
            { itemprop: 'description', content: description },
            { itemprop: 'image', content: image },

            { name: 'og:title', content: title },
            { name: 'og:description', content: description },
            { name: 'og:type', content: "website" },
            { name: 'og:image', content: image },
            { name: 'theme-color', content: "#ffffff" },
            { name: 'msapplication-TileColor', content: "#da532c" },
            { name: 'msapplication-config', content: "/meta/browserconfig.xml" }
        ],
        link: [
            { rel: "icon", type: "image/x-icon", href: "/meta/favicon.ico" },
            { rel: "apple-touch-icon", sizes: "180x180", href: "/meta/apple-touch-icon.png" },
            { rel: "icon", type: "image/png", sizes: "32x32", href: "/meta/favicon-32x32.png" },
            { rel: "icon", type: "image/png", sizes: "16x16", href: "/meta/favicon-16x16.png" },
            { rel: "manifest", href: "/meta/site.webmanifest" },
            { rel: "mask-icon", href: "/safari-pinned-tab.svg", color:  "#5bbad5" },
        ],
        htmlAttrs: {
            lang: "ru"
        }
    };
}
