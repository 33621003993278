import DocsService from "~/services/docsService";

export default defineNuxtPlugin((nuxtApp) => {

    const $config = useRuntimeConfig();
    const params : IServiceParams = {$config}

    const modules: IApiInstance = {
        docs: new DocsService(params),
    };

    return {
        provide: {
            api: modules
        }
    };
});
