const projectName = "Хи-квадрат";

export default {
    projectName,
    getTitle(title: string) {
        return `${title}`
    },
    projectYear: 2008,
    currentYear: new Date(Date.now()).getFullYear()
}
