<script setup lang="ts">
if(process.client) {
  import('@dotlottie/player-component');
}

const $props = defineProps({
  src: {
    type: String,
    required: true,
    default: ""
  },
  autoplay: {
    type: Boolean,
    required: false,
    default: true
  },
  loop: {
    type: Boolean,
    required: false,
    default: true
  },
  speed: {
    type: Number,
    required: false,
    default: 1
  }
})

function Play() {
  player.value.play();
}

function onLoopComplete(e) {
  if($props.loop === false) {
    e.target.seek("100%");
  }
}

const player = ref(null);
onMounted(() => {
  player.value.addEventListener("complete", onLoopComplete);
})

defineExpose({Play})
</script>

<template>
  <dotlottie-player
    class="lottie"
    :src="$props.src"
    :loop.attr="$props.loop"
    :speed.attr="$props.speed"
    :autoplay.attr="$props.autoplay"
    :background.attr="'none'"
    ref="player"
  />
</template>
