import {RuntimeConfig} from "nuxt/schema";
import {$fetch, FetchOptions} from "ofetch";
import {$Fetch} from "nitropack";

export class ServiceBase {
    protected getAPIUrl() : string {
        return process.client ? `${this.$config.public.API_PATH}` : `${this.$config.public.API_PATH_ABSOLUTE}/v01_`
    }

    protected $config: RuntimeConfig

    constructor(params : IServiceParams) {
        this.$config = params.$config;
    }
}

export function MakeServiceRequest<T>(url: string, options: FetchOptions) : Promise<$Fetch> {
    return $fetch(url, {
        ...options,
        method: options?.method || "GET",
        headers: {
            ...(options.headers || {}),
            Prefer: 'params=single-object'
        }
    }).catch((error: any) => {
        let parsed = error.data;
        try{
            parsed = JSON.parse(error.data)
        } catch(e) {}
        return parsed
    });
}

export function TransformResponse() {
    // TODO: IMPLEMENT
}
