import {MakeServiceRequest, ServiceBase} from "~/services/core/serviceBase";
import {$Fetch} from "nitropack";

export default class DocsService extends ServiceBase {
    public get(codes: Array<string> | undefined) : Promise<$Fetch> {
        return MakeServiceRequest<any>(`${this.getAPIUrl()}get_file_list?codeList=${codes?.join('|')}`, {
            method: "GET"
        });
    }
}
